<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card>
            <div class="ml-2">
              <b-button
                v-b-modal.assignSpecialRate
                variant="primary"
                class="btn-tour-skip mt-1"
              >
                <FeatherIcon icon="PlusCircleIcon" />
                <span class="mx-2 align-middle">Assign Special Rate</span>
              </b-button>
            </div>
            <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
              <template v-slot="{ props }">
                <div
                  v-if="props.column.field === 'action'"
                  class="d-flex justify-content-center"
                >
                  <div>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="editSpecialRate(props.row)">
                        <feather-icon size="16" icon="EditIcon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteSpecialRate(props.row)">
                        <feather-icon
                          size="16"
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </template>
            </MyTable>
          </b-card>
        </b-col>
      </b-row>
      <b-modal ref="generateLabel" hide-footer hide-title centered size="lg">
        <OrderLabel :order="orderLabel" />
      </b-modal>
      <b-modal
        id="assignSpecialRate"
        title="Assign Special Rate"
        hide-footer
        centered
        size="lg"
        @hidden="clearSpecialRate"
      >
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col>
              <b-form-group label="Company/User Name">
                <validation-provider
                  #default="{ errors }"
                  name="Company/User Name"
                  rules="required"
                >
                  <v-select
                    v-model="specialRate.companyUserId"
                    :options="user"
                    label="name"
                    :reduce="(user) => user.id"
                    searchable
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Unit Price">
                <validation-provider
                  #default="{ errors }"
                  name="Unit Price"
                  rules="required|positive"
                >
                  <b-form-input v-model="specialRate.unitPrice" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Double Charged">
                <validation-provider
                  #default="{ errors }"
                  name="Double Charged"
                  rules="required|positive"
                >
                  <b-form-input v-model="specialRate.doubleCharge" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="my-2">
            <b-col>
              <b-form-group label="Select Category">
                <validation-provider
                  #default="{ errors }"
                  name="Select Category"
                  rules="required"
                >
                  <vSelect
                    v-model="specialRate.categoryId"
                    :reduce="(category) => category.id"
                    :options="category"
                    label="category"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Unit Price Cost">
                <validation-provider
                  #default="{ errors }"
                  name="Unit Price Cost"
                  rules="required|positive"
                >
                  <b-form-input v-model="specialRate.unitPriceCost" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Double Charged Cost">
                <validation-provider
                  #default="{ errors }"
                  name="Double Charged Cost"
                  rules="required|positive"
                >
                  <b-form-input v-model="specialRate.doubleChargeCost" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Convert Risk">
                <validation-provider
                  #default="{ errors }"
                  name="Convert Risk"
                  rules="required|positive"
                >
                  <b-form-input v-model="specialRate.convertRisk" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Start Date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="specialRate.startDate"
                    :config="{
                      dateFormat: 'Y-m-d',
                    }"
                    class="form-control"
                    placeholder="Choose Date"
                    readonly="readonly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="End Date">
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="specialRate.endDate"
                    :config="{
                      dateFormat: 'Y-m-d',
                      minDate: specialRate.startDate,
                    }"
                    class="form-control"
                    placeholder="Choose Date"
                    readonly="readonly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-center my-2">
            <b-button variant="primary" @click="submitSpecialRate()"
              >Submit</b-button
            >
          </b-row>
        </validation-observer>
      </b-modal>
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Processing... Please wait.</h3>
      </div>
    </template>
  </b-overlay>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import OrderLabel from "../Label/OrderLabel.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    OrderLabel,
  },

  data() {
    return {
      orderLabel: {},
      dateFilter: {},
      dummyData: {},
      specialRate: {},
      isEditing: "",
      type: "",
      isLoading: true,
      orderID: null,
      idx: null,
      filter: "Active",
      optionsStatus: {
        "Active": "Active",
        "In Warehouse": "In Warehouse",
        "Arrived At Branch": "Arrived At Branch",
        "Out For Delivery": "Out For Delivery",
        Delivered: "Delivered",
        "Close Store": "Close Store",
        "Second Delivery": "Second Delivery",
        "Assigned for Last Mile": "Assigned for Last Mile",
      },
      optionsCancel: {
        "Duplicate Order": "Duplicate Order",
        "Wrong Recipient's Address": "Wrong Recipient's Address",
        "Client Not Ready for Pick Up": "Client Not Ready for Pick Up",
        "Cancel Order": "Cancel Order",
        "IT Testing Order": "IT Testing Order",
      },
      filterLabels: [
        { label: "Active" },
        { label: "Assigned" },
        { label: "Scheduled" },
        { label: "History" },
        { label: "Cancelled" },
        { label: "Failed Payment" },
      ],
      columns: [
        {
          label: "Company/User Name",
          field: "company.name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Category ",
          field: "category.name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Unit Price",
          field: "unit_price",
          tdClass: "text-center align-middle",
        },
        {
          label: "Unit Price Cost",
          field: "unit_price_cost",
          tdClass: "text-center align-middle",
        },
        {
          label: "Double Charged",
          field: "double_charge",
          tdClass: "text-center align-middle",
        },
        {
          label: "Double Charged Cost",
          field: "double_charge_cost",
          tdClass: "text-center align-middle",
        },
        {
          label: "Convert Risk",
          field: "convert_risk",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      required,
      category: [],
      user: [],
      isLoading: false,
    };
  },

  async created() {
    this.isLoading = true;
    if (this.$route.query.date) {
      this.dateFilter = this.$route.query.date;
      this.filter = this.$route.query.filter;
    } else {
      this.dateFilter = new Date().toJSON().slice(0, 10);
    }
    this.specialRate.startDate = "";
    this.specialRate.endDate = "";
    this.getOrders();
    this.getCategories();
    this.getUser();
    this.isLoading = false;
  },

  methods: {
    getOrders() {
      this.isLoading = true;
      this.$http.get(`special_rate`).then((response) => {
        if (response.data.data?.length > 0) {
          this.rows = response.data.data;
        } else {
          this.rows = [];
        }
        this.isLoading = false;
      });
    },

    getCategories() {
      this.$http
        .get("get_location_category")
        .then((response) => {
          this.category = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUser() {
      this.$http
        .get("get_b2c_outlet_users")
        .then((response) => {
          this.user = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async submitSpecialRate() {
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) {
        return;
      }
      this.isLoading = true;
      this.$bvModal.hide("assignSpecialRate");
      if (this.isEditing) {
        this.$http
          .put(`special_rate/${this.isEditing}`, this.specialRate)
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              let errors = response.data.errors;
              for (let error in errors) {
                for (let i = 0; i < errors[error].length; i++) {
                  this.$toast.error(errors[error][i], {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                }
              }
            }
            this.getOrders();
            this.specialRate = {};
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      } else {
        this.$http
          .post("special_rate", this.specialRate)
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              let errors = response.data.errors;
              for (let error in errors) {
                for (let i = 0; i < errors[error].length; i++) {
                  this.$toast.error(errors[error][i], {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                }
              }
            }
            this.getOrders();
            this.specialRate = {};
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },

    clearSpecialRate() {
      this.specialRate = {};
      this.isEditing = "";
    },

    editSpecialRate(row) {
      this.specialRate.companyUserId = row.company.id;
      this.specialRate.categoryId = row.category.id;
      this.specialRate.unitPrice = row.unit_price;
      this.specialRate.unitPriceCost = row.unit_price_cost;
      this.specialRate.doubleCharge = row.double_charge;
      this.specialRate.startDate = row.start_date;
      this.specialRate.endDate = row.end_date;
      this.specialRate.doubleChargeCost = row.double_charge_cost;
      this.specialRate.convertRisk = row.convert_risk;
      this.isEditing = row.id;
      this.$bvModal.show("assignSpecialRate");
    },
    async deleteSpecialRate(row) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete this special rate`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-2",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          const response = await this.$http.delete(`special_rate/${row.id}`);
          if (response.data.status === true) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            this.getOrders();
            this.isLoading = false;
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
